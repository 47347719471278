const getters = {

    //  从联系人中提取出首字母 再排序 

    contactsInitialList: state => {
        var initialList = [],
            allContacts = state.allContacts,
            max = allContacts.length
        for (var i = 0; i < max; i++) {
            if (initialList.indexOf(allContacts[i].initial.toUpperCase()) == -1) {
                initialList.push(allContacts[i].initial.toUpperCase())
            }
        }
        return initialList.sort()
    },

    // 将联系人根据首字母进行分类
    contactsList: (state, getters) => {
        var contactsList = {},
            allContacts = state.allContacts,
            max = allContacts.length;
        for (var i = 0; i < getters.contactsInitialList.length; i++) {
            var protoTypeName = getters.contactsInitialList[i]
            contactsList[protoTypeName] = []
            for (var j = 0; j < max; j++) {
                if (allContacts[j].initial.toUpperCase() === protoTypeName) {
                    contactsList[protoTypeName].push(allContacts[j])
                }
            }
        }
        return contactsList
    },

    // 银行卡list ，过滤零钱/零钱通
    bankList(state) {
        return state.cardList.filter((_,index) => index > 1)
    },
    // 银行卡list ，过滤零钱/零钱通
    bankLists(state) {
        return state.cardList.filter((_,index) => index != 1)
    },
    // 付款方式具体信息
    payType(state) {
        return state.cardList.filter(item => item.id == state.payTypeId)[0]
    }

}

export default getters