const mutations = {
    //切换语言 后期需要
    switchLang(state, lang) {
        state.currentLang = lang
        // Vue.config.lang = lang
        document.cookie = "VR_LANG=" + lang + "; path=/;domain=.snail.com"
        // location.reload()
    },
    //设置当前页面名字
    setPageName(state, name) {
        state.currentPageName = name
    },
    //设置前一页名字 已遗弃
    // setBackPageName(state, name) {
    //     state.backPageName = name
    // },
    //当 search 组件全屏/非全屏时 切换公共头部状态
    toggleHeaderStatus(state, status) {
        state.headerStatus = status
    },
    //切换“微信”页中右上角菜单
    toggleTipsStatus(state, status) {
        if (status == -1) {
            state.tipsStatus = false
        } else {
            state.tipsStatus = !state.tipsStatus
        }
    },
    //增加未读消息数
    addNewMsg(state) {
        state.newMsgCount > 99 ? state.newMsgCount = "99+" : state.newMsgCount++
    },
    //减少未读消息数
    minusNewMsg(state) {
        state.newMsgCount < 1 ? state.newMsgCount = 0 : state.newMsgCount--
    },
    //将消息置顶 待完成
    // setMsgStick(state, mid) {

    // },
    //取消置顶消息 待完成
    // cancelMsgStick(state, mid) {

    // }
    // 设置付款方式
    setPayTypeId(state, payload) {
        state.payTypeId = payload.id
    },
    // 设置付款方式
    setActiveCardId(state, payload) {
        state.activeCardId = payload.id
    },
    // 设置个人信息
    setUserInfo(state, payload) {
        state.userInfo = payload
    },
    // 设置付款信息
    setPayInfo(state, payload) {
        state.payInfo = payload
    },
    // 设置零钱/零钱通余额
    setFund(state, payload) {
        state.fund = payload
    },
    setBankList(state, payload) {
        state.cardList = [state.cardList[0],state.cardList[1], ...payload]
    },
    // 添加账单
    addBill(state, payload) {
        state.billList.push(payload)
    },
    // 删除账单
    delBillList(state, payload) {
        state.billList = state.billList.filter(bill => bill.id != payload)
    }
}
export default mutations