import Vue from 'vue'
import Vuex from 'vuex'
import OfficialAccounts from "./official-account" //存放所有关注的公众号
import contact from './contacts' //存放所有联系人的数据
import mutations from "./mutations"
import actions from "./actions"
import getters from "./getters"
import createPersistedState from 'vuex-persistedstate'
Vue.use(Vuex)
    // 统一管理接口域名 
let apiPublicDomain = '//vrapi.snail.com/'
const state = {
    currentLang: "zh", //当前使用的语言 zh：简体中文 en:英文 后期需要
    newMsgCount: 0, //新消息数量
    allContacts: contact.contacts, //所有联系人
    OfficialAccounts: OfficialAccounts, //所有关注的公众号
    currentPageName: "微信", //用于在wx-header组件中显示当前页标题
    //backPageName: "", //用于在返回按钮出 显示前一页名字 已遗弃
    headerStatus: true, //显示（true）/隐藏（false）wx-header组件
    tipsStatus: false, //控制首页右上角菜单的显示(true)/隐藏(false)
    // 所有接口地址 后期需要
    apiUrl: {
        demo: apiPublicDomain + ""
    },
    msgList: {
        stickMsg: [], //置顶消息列表 后期需要
        baseMsg: [{ //普通消息列表
                "mid": 1, //消息的id 唯一标识，重要
                "type": "friend",
                "group_name": "",
                "group_qrCode": "",
                "read": true, //true；已读 false：未读
                "newMsgCount": 1,
                "quiet": false, // true：消息免打扰 false：提示此好友/群的新消息
                "msg": [{ //对话框的聊天记录 新消息 push 进
                    "text": "长按这些白色框消息，唤醒消息操作菜单，长按这些白色框消息，唤醒消息操作菜单",
                    "date": 1488121964495,
                    "name": "阿荡",
                    "headerUrl": "/images/header/header01.png"
                }, {
                    "text": '点击空白处，操作菜单消失',
                    "date": 1488121964495,
                    "name": "阿荡",
                    "headerUrl": "/images/header/header01.png"
                }, {
                    "text": '来呀 快活啊',
                    "date": 1488121964495,
                    "name": "阿荡",
                    "headerUrl": "/images/header/header01.png"
                }],
                "user": [contact.getUserInfo('wxid_zhaohd')] // 此消息的用户数组 长度为1则为私聊 长度大于1则为群聊
            },
            {
                "mid": 2,
                "type": "group",
                "group_name": "晋江项目",
                "group_qrCode": "",
                "read": false,
                "newMsgCount": 1,
                "quiet": true,
                "msg": [{
                        "text": "长按消息，唤醒消息操作菜单",
                        "date": 1488122964495,
                        "name": "夜华",
                        "headerUrl": "/images/header/yehua.jpg"
                    }, {
                        "text": '点击空白处，操作菜单消失',
                        "date": 1488122964495,
                        "name": "阿荡",
                        "headerUrl": "/images/header/header01.png"
                    },
                    {
                        "text": '我试一试',
                        "date": 1488122964495,
                        "name": "夜华",
                        "headerUrl": "/images/header/yehua.jpg"
                    }
                ],
                "user": [contact.getUserInfo('wxid_zhaohd'), contact.getUserInfo('wxid_yehua')]
            },
            {
                "mid": 3,
                "type": "group",
                "group_name": "一起玩",
                "group_qrCode": "",
                "read": true,
                "newMsgCount": 1,
                "quiet": true,
                "msg": [{
                    "text": '冒个泡',
                    "date": 1488122964495,
                    "name": "诸葛亮",
                    "headerUrl": "/images/header/zhugeliang.jpg"
                }],
                "user": [contact.getUserInfo('wxid_zhenji'), contact.getUserInfo('wxid_zhugeliang'), contact.getUserInfo('wxid_zhaohd')]
            },
            {
                "mid": 4,
                "type": "friend",
                "group_name": "",
                "group_qrCode": "",
                "read": false,
                "newMsgCount": 4,
                "quiet": false,
                "msg": [{
                    "text": "长按消息，唤醒消息操作菜单",
                    "date": 1488122764495,
                    "name": "孙权",
                    "headerUrl": "/images/header/sunquan.jpg"
                }, {
                    "text": '点击空白处，操作菜单消失',
                    "date": 1488122764495,
                    "name": "孙权",
                    "headerUrl": "/images/header/sunquan.jpg"
                }, {
                    "text": '从世界去看美丽中国',
                    "date": 1488122764495,
                    "name": "孙权",
                    "headerUrl": "/images/header/sunquan.jpg"
                }],
                "user": [contact.getUserInfo('wxid_sunquan')]
            },
            {
                "mid": 5,
                "type": "friend",
                "group_name": "",
                "group_qrCode": "",
                "read": false,
                "newMsgCount": 4,
                "quiet": false,
                "msg": [{
                    "text": '可以~ ',
                    "date": 1488118364495,
                    "name": "孙尚香",
                    "headerUrl": "/images/header/sunshangxiang.jpg"
                }],
                "user": [contact.getUserInfo('wxid_sunshangxiang')]
            },
            {
                "mid": 6,
                "type": "friend",
                "group_name": "",
                "group_qrCode": "",
                "read": false,
                "newMsgCount": 4,
                "quiet": true,
                "msg": [{
                    "text": '你说的我不知道 要明天再看。 ',
                    "date": 1488117927495,
                    "name": "关羽",
                    "headerUrl": "/images/header/guangyu.jpg"
                }],
                "user": [contact.getUserInfo('wxid_zhenji1')]
            },
            {
                "mid": 7,
                "type": "friend",
                "group_name": "",
                "group_qrCode": "",
                "read": false,
                "newMsgCount": 4,
                "quiet": true,
                "msg": [{
                    "text": '小米公司:举报：内部新品永远买不到 ',
                    "date": 1723906643499,
                    "name": "关羽1",
                    "headerUrl": "/images/header/guangyu.jpg"
                }],
                "user": [contact.getUserInfo('wxid_zhenji2')]
            },
            {
                "mid": 8,
                "type": "friend",
                "group_name": "",
                "group_qrCode": "",
                "read": false,
                "newMsgCount": 4,
                "quiet": true,
                "msg": [{
                    "text": '还有三天 ',
                    "date": 1723906643499,
                    "name": "关羽2",
                    "headerUrl": "/images/header/guangyu.jpg"
                }],
                "user": [contact.getUserInfo('wxid_zhenji3')]
            },
            {
                "mid": 9,
                "type": "friend",
                "group_name": "",
                "group_qrCode": "",
                "read": false,
                "newMsgCount": 4,
                "quiet": true,
                "msg": [{
                    "text": '三十号左右吧',
                    "date": 1723906623499,
                    "name": "关羽3",
                    "headerUrl": "/images/header/guangyu.jpg"
                }],
                "user": [contact.getUserInfo('wxid_zhenji4')]
            },
            {
                "mid": 10,
                "type": "friend",
                "group_name": "",
                "group_qrCode": "",
                "read": false,
                "newMsgCount": 4,
                "quiet": true,
                "msg": [{
                    "text": '交易提醒',
                    "date": 1488117964495,
                    "name": "关羽3",
                    "headerUrl": "/images/header/guangyu.jpg"
                }],
                "user": [contact.getUserInfo('wxid_zhenji5')]
            },
            {
                "mid": 11,
                "type": "friend",
                "group_name": "",
                "group_qrCode": "",
                "read": false,
                "newMsgCount": 4,
                "quiet": true,
                "msg": [{
                    "text": '尼玛！',
                    "date": 1488117964495,
                    "name": "关羽3",
                    "headerUrl": "/images/header/guangyu.jpg"
                }],
                "user": [contact.getUserInfo('wxid_zhenji6')]
            },
            {
                "mid": 12,
                "type": "friend",
                "group_name": "",
                "group_qrCode": "",
                "read": false,
                "newMsgCount": 4,
                "quiet": true,
                "msg": [{
                    "text": '逻辑要清晰啊',
                    "date": 1723906621499,
                    "name": "关羽3",
                    "headerUrl": "/images/header/guangyu.jpg"
                }],
                "user": [contact.getUserInfo('wxid_zhenji7')]
            },
            {
                "mid": 13,
                "type": "friend",
                "group_name": "",
                "group_qrCode": "",
                "read": false,
                "newMsgCount": 4,
                "quiet": true,
                "msg": [{
                    "text": '不知道',
                    "date": 1723901621499,
                    "name": "关羽3",
                    "headerUrl": "/images/header/guangyu.jpg"
                }],
                "user": [contact.getUserInfo('wxid_zhenji8')]
            },
            {
                "mid": 14,
                "type": "friend",
                "group_name": "",
                "group_qrCode": "",
                "read": false,
                "newMsgCount": 4,
                "quiet": true,
                "msg": [{
                    "text": '你说了算',
                    "date": 1723901621499,
                    "name": "关羽3",
                    "headerUrl": "/images/header/guangyu.jpg"
                }],
                "user": [contact.getUserInfo('wxid_zhenji9')]
            },
            {
                "mid": 15,
                "type": "friend",
                "group_name": "",
                "group_qrCode": "",
                "read": false,
                "newMsgCount": 4,
                "quiet": true,
                "msg": [{
                    "text": '能搞就搞 搞不了拉倒',
                    "date": 1723902621499,
                    "name": "关羽3",
                    "headerUrl": "/images/header/guangyu.jpg"
                }],
                "user": [contact.getUserInfo('wxid_zhenji10')]
            },
            {
                "mid": 16,
                "type": "friend",
                "group_name": "",
                "group_qrCode": "",
                "read": false,
                "newMsgCount": 4,
                "quiet": true,
                "msg": [{
                    "text": '滚犊子',
                    "date": 1723898433499,
                    "name": "关羽3",
                    "headerUrl": "/images/header/guangyu.jpg"
                }],
                "user": [contact.getUserInfo('wxid_zhenji11')]
            },
            {
                "mid": 17,
                "type": "friend",
                "group_name": "",
                "group_qrCode": "",
                "read": false,
                "newMsgCount": 4,
                "quiet": true,
                "msg": [{
                    "text": '搞笑吧你',
                    "date": 1723898433499,
                    "name": "关羽3",
                    "headerUrl": "/images/header/guangyu.jpg"
                }],
                "user": [contact.getUserInfo('wxid_huangyueying')]
            },
            {
                "mid": 18,
                "type": "friend",
                "group_name": "",
                "group_qrCode": "",
                "read": false,
                "newMsgCount": 4,
                "quiet": true,
                "msg": [{
                    "text": '不可以',
                    "date": 1723894223495,
                    "name": "关羽3",
                    "headerUrl": "/images/header/guangyu.jpg"
                }],
                "user": [contact.getUserInfo('wxid_zhenji12')]
            },
   /*         {
                "mid": 19,
                "type": "friend",
                "group_name": "",
                "group_qrCode": "",
                "read": false,
                "newMsgCount": 4,
                "quiet": true,
                "msg": [{
                    "text": '你自己好好想想',
                    "date": 1488117964495,
                    "name": "关羽3",
                    "headerUrl": "/images/header/guangyu.jpg"
                }],
                "user": [contact.getUserInfo('wxid_sunshangxiang')]
            },*/
            {
                "mid": 20,
                "type": "friend",
                "group_name": "",
                "group_qrCode": "",
                "read": false,
                "newMsgCount": 4,
                "quiet": true,
                "msg": [{
                    "text": 'ok ',
                    "date": 1723890013495,
                    "name": "关羽3",
                    "headerUrl": "/images/header/guangyu.jpg"
                }],
                "user": [contact.getUserInfo('wxid_zhugeliang')]
            }
        ]
    },
    userInfo: { username: '张浩', nickname: 'lisi', avatar: '/images/header/header01.png', phone: 18845268536, wxcode: '', email: '' }, // 个人信息
    payInfo: { username: '李斯', nickname: 'lisi', avatar: '' }, // 付款信息
    fund: { // 零钱/零钱通
        coin: 2342, 
        coinCash: 23222
    },
    cardList: [ // 零钱/零钱通/银行卡列表
        {
            id: 0,
            icon: '/images/cash.png',
            activeIcon: '/images/cash.png', 
            title: '零钱',
            isOut: false
        },
        {
            id: 1,
            icon: '/images/lingqiantong.png', 
            activeIcon: '/images/lingqiantong.png', 
            title: '零钱通',
            isOut: false
        },
/*        {
            activeIcon: "/images/zhaoshang-bank.png",
            bg: "/images/zhaoshang-bg.png",
            bgColor: "#C65255",
            icon: "/images/zhaoshang-bank.png",
            id: 1717155593041,
            isOut: false,
            no: "1234,3456,5467,3456",
            title: "招商银行",  
            type: "储蓄卡"
        },   { 
            id: 3353,
            icon: '/images/zhongguo.png', 
            activeIcon: '/images/zhongguo.png', 
            title: '中国银行',
            type: '储蓄卡',
            no: "1234,3456,5467,3456",
            bg: '/images/nongshang-bg.png',
            bgColor: '#BD8044'
        },
        { 
            id: 3534,
            icon: '/images/jianshe.png', 
            activeIcon: '/images/jianshe.png', 
            title: '建设银行',
            type: '储蓄卡',
            no: "1234,3456,5467,3456",
            bg: '/images/guangfa-bg.png',
            bgColor: '#C65255'
        },*/
        { 
            id: 235343,
            icon: '/images/youzheng.png', 
            activeIcon: '/images/youzheng.png', 
            title: '邮政银行',
            type: '储蓄卡',
            no: "1234,3456,5467,3456",
            bg: '/images/pufa-bg.png',
            bgColor: '#BD8044'
        },
    ],
    bankDefaultList: [
        { 
            id: 0,
            icon: '/images/zhaoshang-bank.png',
            activeIcon: '/images/zhaoshang-bank.png', 
            title: '招商银行',
            type: '储蓄卡',
            bg: '/images/zhaoshang-bg.png',
            bgColor: '#C65255',
        },
        { 
            id: 1,
            icon: '/images/zhongguo.png', 
            activeIcon: '/images/zhongguo.png', 
            title: '中国银行',
            type: '储蓄卡',
            bg: '/images/nongshang-bg.png',
            bgColor: '#BD8044'
        },
        { 
            id: 2,
            icon: '/images/jianshe.png', 
            activeIcon: '/images/jianshe.png', 
            title: '建设银行',
            type: '储蓄卡',
            bg: '/images/guangfa-bg.png',
            bgColor: '#C65255'
        },
        { 
            id: 3,
            icon: '/images/youzheng.png', 
            activeIcon: '/images/youzheng.png', 
            title: '邮政银行',
            type: '储蓄卡',
            bg: '/images/pufa-bg.png',
            bgColor: '#BD8044'
        },
        { 
            id: 4,
            icon: '/images/gongshang-bank.png', 
            activeIcon: '/images/gongshang-bank.png', 
            title: '工商银行',
            type: '储蓄卡',
            bg: '/images/gongshang-bg.png',
            bgColor: '#C65255'
        },
        { 
            id: 5,
            icon: '/images/jiaotong.png', 
            activeIcon: '/images/jiaotong.png', 
            title: '交通银行',
            type: '储蓄卡',
            bg: '/images/xinye-bg.png',
            bgColor: '#1B65A2'
        },
    ],
    payTypeId: 0, // 付款方式，默认0既零钱
    activeCardId: 2,
    billList: [ // 账单
        // {
        //     id: '',
        //     avatar: '',
        //     text: '',
        //     value: '',
        //     transferTime: '',
        //     incomeTime: '',
        //     type: '',
        //     number: '',
        //     balance: '',
            
        // }
    ]

}
export default new Vuex.Store({
    state,
    mutations,
    actions,
    getters,
    plugins: [createPersistedState({
        storage: localStorage,
    })]
})