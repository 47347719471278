import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import vuex from 'vue'
import App from './App'
import router from './router'
import store from './vuex/store'
import FastClick from 'fastclick' //使用 fastclick 解决移动端 300ms 点击延迟
import filters from './filters' //将全部过滤器放在 filters/index.js 中便于管理
import Vant from 'vant';
import 'vant/lib/index.css';

//技巧 同时 use 多个插件 被依赖的插件应放在偏后方
Vue.use(VueAxios, axios, vuex)
Vue.use(Vant);
// 注册全局过滤器
filters(Vue)

Vue.config.productionTip = false //将此值设置为 false ,会关闭 Vue 启动时的提示信息，推荐
Vue.prototype.$adminUrl = 'https://cnm.ktv360.xyz'
const whiteList = ['/wehchat/validate']
FastClick.attach(document.body)
// 校验是否登陆  只要路由跳转，刷新   进行加载
router.beforeEach((to, from, next) => {
  // 获取cookie中缓存的用户信息
  let is_root = window.localStorage.getItem('is_root')
  let is_admin = window.localStorage.getItem('is_admin')
console.log(to.path)

if(to.path == '/wehchat/adminvalidate' && is_admin == 1){
    next({path: '/wehchat/adminal'})
}
  if (is_root != 1) {

    // 未登录的情况下
    if (whiteList.indexOf(to.path) !== -1 || to.path.substr(0, 3) === '/s/') {
      next()
    } else {
      // 其他没有访问权限的页面被重定向到登录页面。
      next({path: '/wehchat/validate'})
      // 完成进度条
 
    }
  } else {
    // 如果已登录，则重定向到主页
    if (to.path === '/wehchat/validate') {
      next({path: '/contact'})
      // 完成进度条

    } else {
      next()
    }
  }
})
new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App)
})
// 运行 vue init webpack命令新建项目时 可以选择关闭 ESLint
// 若新建项目时开启了 ESLint .eslintignore 文件，告诉 ESLint 去忽略特定的文件和目录。
// .eslintignore 文件是一个纯文本文件，其中的每一行都是一个 glob 模式表明哪些路径应该忽略检测